<template>
  <div class="BetweenTasks">
  <img src="../assets/indicator.svg" id="indicator">
      <h2>Task 1 Fertig</h2>
      <p>Als nächstes wird eine neue Webseite angezeigt. Auch hier soll wieder der oben erwähnte Task ausgeführt werden. Lesen Sie oben den Task und drücken Sie wieder den Startbutton.</p>
      
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'BetweenTasks',
  created: function(){
  },
  methods: { 
  }
}
</script>

<style scoped>
  .BetweenTasks{
    display: block;
    width: 60%;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
  }

   #indicator{
    width: 15%;
    margin-top: 1px;
    display: block;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
  }
</style>