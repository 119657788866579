<template>
  <div class="mainDiv">
    <div id="searchDiv">
        
        <label id="searchLabel" v-if="showSearchLabel">Suche: </label>
        <input type="text" @click="searchBarClicked" class="input-logo" :placeholder="placeholderText" @keyup.enter="enterPressed">
    </div>
    
    
    <div class="hsluDiv">
        
        <div class="mainPage">
            <h2>MyCampus der Hochschule Luzern</h2>
            Das Webportal «MyCampus» bietet Studierenden und Dozierenden Zugang zu Informationen aus dem schulisch-administrativen Bereich der Hochschule Luzern.
            <br>
            <h4>Bachelor- und Master-Studienangebot</h4>
            Die Hochschule Luzern – Wirtschaft bereitet Studierende mit Bachelor-Studiengängen optimal auf den Berufseinstieg oder ein weiterführendes Master-Studium vor.     
        </div>
        <div class="multiInput" v-if="showMultiInput">
            <h3>Login</h3><br>
            Benutzername:<br>
            <input type="text" placeholder="">
            <br><br>
            Passwort:<br>
            <input type="text" placeholder="">
        </div>
    </div>
    <img src="../assets/mobileFooter.png" id="footer">
    {{taskOption}} mobile <br>
  </div>
</template>

<style scoped>
#searchDiv{
  display: flex;
  width: 100%;
  margin-left: auto;
  font-size: 10pt;
  padding-top: 10px;
  float: right;
  background-color: #415e6c;
}
h2, h4{
    margin-top: 2%;
}
#footer{
  width: 100%;
}

#logo{
  width: 10%;
  margin-left: 20%;
  display: inline;
}
#menu{
  width: 80%;
  margin-left: auto;
  display: block;
}

#searchLabel{
  margin: auto;
}

.input-logo{
        background-image:url("../assets/searchicon.svg"); 
        background-position:right;   
        background-repeat:no-repeat;
        margin: 2px;
        padding-left:1px;
        font-size: 16px;
        width: 180px;
        border: solid #737373 1px;
        margin-left: auto;
  }
.hsluDiv{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.mainPage{
  width: 100%;
    margin: 5%;

}

.multiInput{
    background: lightgray;
    padding: 1%;
    margin: 5%;
}
</style>
<script>
// @ is an alias to /src
var placeholderText = ""
var showSearchLabel = false;
var showMultiInput = false;
export default {
  name: 'HsluMobile',
  props: {
    taskOption: String
  },
  data: function(){
      return {
      placeholderText,
      showSearchLabel,
      showMultiInput
    }
  },
  created: function(){
     if (this.taskOption == 'HsluLabel') {
       this.showSearchLabel = true
     } else if (this.taskOption == 'HsluPlaceholder') {
       this.placeholderText = "Suchen"
     } else if (this.taskOption == 'HsluMultiInput') {
       this.showMultiInput = true
     } 
  },
  methods: {
    searchBarClicked() {
      this.$emit('stop-timer')
    },
    enterPressed(){
      console.log("enterpressed")
      this.$emit('finished')     
    }
  }
}
</script>