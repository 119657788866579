<template>
  <div class="mainDiv">
    <div id="searchDiv">
        <p class="linksSearch">Kontakt Login Sitemap De|En </p>
        <label id="searchLabel" v-if="showSearchLabel">Suche: </label>
        <input type="text" @click="searchBarClicked" class="input-logo" :placeholder="placeholderText" @keyup.enter="enterPressed">
    </div>
    <img src="../assets/hslulogo.png" id="logo">
    <img src="../assets/menu.png" id="menu">
    <div class="hsluDiv">
        <div class="multiInput" v-if="showMultiInput">
            <h3>Login:</h3>
            Benutzername:
            <input type="text" placeholder="">
            <br>
            Passwort:
            <input type="text" placeholder="">
        </div>
        <div class="mainPage">
            <h2>MyCampus der Hochschule Luzern</h2>
            Das Webportal «MyCampus» bietet Studierenden und Dozierenden Zugang zu Informationen aus dem schulisch-administrativen Bereich der Hochschule Luzern.
            <br>
            <h4>Bachelor- und Master-Studienangebot</h4>
            Die Hochschule Luzern – Wirtschaft bereitet Studierende mit Bachelor-Studiengängen optimal auf den Berufseinstieg oder ein weiterführendes Master-Studium vor.     
        </div>
        
    </div>
    {{taskOption}} <br>
  </div>
</template>

<style scoped>
#searchDiv{
  display: flex;
  width: 50%;
  margin-left: auto;
  font-size: 10pt;
  margin-top: 10px;
}
h2, h4{
    margin-top: 2%;
}

#logo{
  width: 10%;
  margin-left: 20%;
  display: inline;
}
#menu{
  width: 80%;
  margin-left: auto;
  display: block;
}

#searchLabel{
  margin: auto;
}

.linksSearch{
  margin-top: auto;
  margin-bottom: 1px;
  margin-right: 5px;
}

.input-logo{
        background-image:url("../assets/searchicon.svg"); 
        background-position:right;   
        background-repeat:no-repeat;
        margin: 2px;
        padding-left:1px;
        font-size: 16px;
        width: 180px;
        border: solid #737373 1px;
  }
.hsluDiv{
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    width: 50%;
    margin: auto;
    margin-top: 5%;
}

.multiInput{
    
    margin-left: 5%;
}
</style>
<script>
// @ is an alias to /src
var placeholderText = ""
var showSearchLabel = false;
var showMultiInput = false;
export default {
  name: 'Hslu',
  props: {
    taskOption: String
  },
  data: function(){
      return {
      placeholderText,
      showSearchLabel,
      showMultiInput
    }
  },
  created: function(){
     if (this.taskOption == 'HsluLabel') {
       this.showSearchLabel = true
     } else if (this.taskOption == 'HsluPlaceholder') {
       this.placeholderText = "Suchen"
     } else if (this.taskOption == 'HsluMultiInput') {
       this.showMultiInput = true
     } 
  },
  methods: {
    searchBarClicked() {
      this.$emit('stop-timer')
    },
    enterPressed(){
      console.log("enterpressed")
      this.$emit('finished')     
    }
  }
}
</script>