<template>
  <div class="Introduction">
    <img src="../assets/indicator.svg" id="indicator">
      <h2>Merc's Testingsite</h2>
      <p>Lesen Sie den Task, welcher im Banner oben ersichtlich ist und drücken Sie dann den Startbutton um die Webseite mit dem Task zu laden.
        Der Task wird auf der nächsten Seite weiterhin ersichtlich sein.
      </p>
      
  </div>
</template>

<style scoped>
  .Introduction{
    display: block;
    width: 60%;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  #indicator{
    width: 15%;
    margin-top: 1px;
    margin-bottom: 2%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>
<script>
// @ is an alias to /src

export default {
  name: 'Introduction',
  created: function(){
  },
  methods: { 
  }
}
</script>
