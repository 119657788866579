<template>
  <div id="app">
    <label id="taskText" v-if="!bShowFinished">{{taskText}}</label>
    <div class="content">
      <keep-alive>
        <introduction id="introduction" v-if="introductionNeeded"></introduction>
      </keep-alive>

      <keep-alive>
        <settings-page v-if="bShowSettingsTask" :taskOption="firstTaskOption" @stop-timer="stopFirstTimer" @show-betweentask="showBetweenTasks"></settings-page>
      </keep-alive>

      <keep-alive>
        <settings-mobile v-if="bShowSettingsMobileTask" :taskOption="firstTaskOption" @stop-timer="stopFirstTimer" @show-betweentask="showBetweenTasks"></settings-mobile>
      </keep-alive>

      <keep-alive>
        <between-tasks id="betweenTasks" v-if="bShowBetweenTasks"></between-tasks>
      </keep-alive>

      <keep-alive>
        <hslu-page v-if="bShowHsluTask" :taskOption="secondTaskOption" @stop-timer="stopSecondTimer" @finished="finishedTest"></hslu-page>
      </keep-alive>

      <keep-alive>
        <hslu-mobile v-if="bShowHsluMobileTask" :taskOption="secondTaskOption" @stop-timer="stopSecondTimer" @finished="finishedTest"></hslu-mobile>
      </keep-alive>

      <div id="finishedDiv" v-if="bShowFinished">
        <h1>Vielen Dank fürs teilnehmen am Test!</h1>
        <p>Bei Fragen wende dich gerne an Marc Häusler: <a href= "mailto:marc.haeusler.01@stud.hslu.ch">marc.haeusler.01@stud.hslu.ch</a></p>
      </div>
    </div>
    <button v-if="noTaskGoing" @click="startBtnPressed" class="startBtn">Start</button>
  </div>
</template>

<script>
import SettingsPage from './components/Settings.vue';
import HsluPage from './components/Hslu.vue';
import Introduction from './views/Introduction.vue';
import BetweenTasks from './views/BetweenTasks.vue';
import SettingsMobile from './components/SettingsMobile.vue';
import HsluMobile from './components/HsluMobile.vue';
//import Hslu from './components/Hslu.vue';
// eslint-disable-next-line no-unused-vars
import { provide, reactive, ref } from 'vue';
import apiSrv from "./services/ApiService";
import { isMobile } from 'mobile-device-detect';
var firstTaskOption, secondTaskOption = "";
var noTaskGoing = true; 
var introductionNeeded = true;
var firstTaskStarted, secondTaskStarted, bShowSettingsTask, bShowSettingsMobileTask, bShowBetweenTasks, bShowHsluTask, bShowHsluMobileTask, bShowFinished = false;
var taskIDRoutes = ["SettingsEmpty", "SettingsLabel", "SettingsMultiInput", "SettingsPlaceholder", "HsluEmpty", "HsluMultiInput", "HsluPlaceholder"];
var locStorage = window.localStorage;
var taskText = 'Benutze das Suchfeld, um nach der Einstellung "proxy" zu suchen.';
export default {
  name: "app",
  components: {
    SettingsPage,
    Introduction,
    BetweenTasks,
    HsluPage,
    SettingsMobile,
    HsluMobile
  },
  created: function (){
    this.updateTaskIDs();
    this.firstTaskOption = this.loadFirstTaskRoute()
    this.secondTaskOption = this.loadSecondTaskRoute();
  },
  methods: {
    updateTaskIDs(){
      this.firstTaskID = Math.floor(Math.random() * Math.floor(4))
      this.secondTaskID = Math.floor(Math.random() * Math.floor(3))
    },
    startBtnPressed(){
      if (!this.firstTaskStarted) {
        this.noTaskGoing = false;
        if (this.bMobile) {
          this.bShowSettingsMobileTask = true;
        } else {
          this.bShowSettingsTask = true;
        }
        this.firstTaskStarted = true;
        this.introductionNeeded = false;

      } else {
        this.noTaskGoing = false;
        if (this.bMobile) {
          this.bShowHsluMobileTask = true;
        } else {
          this.bShowHsluTask = true;
        }
        this.bShowBetweenTasks = false;
        this.secondTaskStarted = true;
      }
      this.startTimer();
    },
    startTimer(){
      this.timer = 0
      console.log("timer started with: " + this.timer)
      this.interval = setInterval(() => this.incrementTimer(), 1);
    },
    incrementTimer() {
      this.timer++
      console.log("timer incremented to: " + this.timer)
    },
    showBetweenTasks()
    {
      console.log("enterpressed arrived")
      this.noTaskGoing = true;
      this.bShowBetweenTasks = true;
      this.bShowSettingsTask = false;
      this.bShowSettingsMobileTask = false;
      this.taskText = 'Benutze das Suchfeld, um nach dem Studiengang "Wirtschaftsinformatik" zu suchen.'
    },
    stopFirstTimer(){
      this.stopAndSaveTimer(1);
      console.log("firstTimeNeeded: " + this.firstTimeNeeded)
    },
    stopSecondTimer(){
      this.stopAndSaveTimer(2)
    },
    stopAndSaveTimer(taskNr){
      if (taskNr == 1) {
        this.firstTimeNeeded = this.timer
      } else {
        this.secondTimeNeeded = this.timer
        this.logAll();
        this.submitDataToDB();
      }
      clearInterval(this.interval)
    },
    logAll(){
      console.log("timer: " + this.timer)
      console.log("task1: "+this.firstTaskID)
      console.log("task2: "+this.secondTaskID)
      console.log(this.firstTimeNeeded)
      console.log(this.secondTimeNeeded)
    },
    loadFirstTaskRoute(){
      return taskIDRoutes[this.firstTaskID];
    },
    loadSecondTaskRoute(){
      return taskIDRoutes[this.secondTaskID + 4];      
    },
    submitDataToDB() {
      if (locStorage.getItem("DataSubmitted") == null) {
        locStorage.setItem("DataSubmitted", "true");
        apiSrv.submitTime(this.firstTaskID, this.firstTimeNeeded, this.secondTaskID, this.secondTimeNeeded, this.bMobile)
      }
    },
    finishedTest(){
      this.submitDataToDB;
      this.bShowFinished = true;
      this.bShowHsluTask = false;
      this.bShowHsluMobileTask = false;
    }
  },
  
  data: function(){
    return {
      firstTaskOption,
      secondTaskOption,
      taskIDRoutes,
      noTaskGoing,
      firstTaskStarted,
      introductionNeeded,
      secondTaskStarted,
      bShowSettingsTask,
      bShowSettingsMobileTask,
      bShowBetweenTasks,
      bShowHsluTask,
      bShowHsluMobileTask,
      taskText,
      bShowFinished,
      bMobile: isMobile ? 1 : 0
      };
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin:0;
  padding: 0;
}

div,
html,
body,
h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#taskText{
    width: 100%;
    display: inline-block;
    padding: 5px;
    vertical-align: middle;
    background-color: lightcoral;
    text-align: center;
  }

#app {
  margin-top: -0px !important;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #2c3e50;
  overflow-x: hidden;
}

#finishedDiv{
  display: block;
  width: 50%;
  align-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}

.startBtn{
  margin: auto;
  margin-top: 5%;
  display: block;
  /* width: 5%; */
}
</style>
