<template>
  <div class="mainDiv">
    <div id="searchDiv">
      <label id="searchLabel" v-if="showSearchLabel">Suche: </label>
      <input type="text" @click="searchBarClicked" class="input-logo" :placeholder="placeholderText" @keyup.enter="enterPressed">
    </div>
    <div class="settingsDiv">
      <div class="nav">
      <div class="navEntry"><img src="../assets/settings.png" id="settingsIcon"></div>
      <div class="navEntry"><img src="../assets/home.png" id="homeIcon"></div>
      <div class="navEntry"><img src="../assets/security.png" id="securityIcon"></div>
      <div class="navEntry"><img src="../assets/sync.png" id="syncIcon"></div>
      </div>
      <div class="contentDiv">
        <label id="titleAllgemein">Allgemein</label>
        <div class="multiInput" v-if="showMultiInput">        
          Default Webseite: 
          <input type="text" placeholder="www.google.com"> <br> <br>
          Default Suchsystem:
          <select>
            <option value="">Google</option>
          </select>
        </div>
        <label class="titleCategory">Start</label>
        <input type="checkbox"> Vorherige Sitzung wiederherstellen <br>
        <input type="checkbox"> Beim Beenden des Browsers warnen <br>
        <input type="checkbox"> Überprüfen ob dies der StandardBrowser ist 
        <label class="titleCategory">Tabs</label>
        <input type="checkbox"> Bei Strg+Tab die Tabs nach letzter Nutzung in absteigender Reihenfolge anzeigen <br>
        <input type="checkbox"> Links in Tabs anstatt in neuen Fenstern öffnen <br>
        <input type="checkbox"> Warnen, wenn mehrere Tabs geschlossen werden <br>
        <input type="checkbox"> Tabs im Vordergrund öffnen <br>
        <input type="checkbox"> Tab-Vorschauen in der Windows-Taskleiste anzeigen
        <label class="titleCategory">Zoom</label>
        <input type="checkbox"> Standardzoom verwenden (100%) <br>
        <input type="checkbox"> Nur Text zoomen <br>
        <input type="checkbox"> Zoom bei Seiten neu laden beibehalten 
        <label class="titleCategory">Benachrichtigungen</label>
        <input type="checkbox"> Benachrichtigungen erlauben <br>
        <input type="checkbox"> Benachrichtigungen auf dem Desktop anzeigen <br>
        <input type="checkbox"> Beanchrichtigungen speichern 
      </div> 
    </div>
    {{taskOption}} mobile
  </div>
</template>

<style scoped>
#searchDiv{
  display: flex;
  width: 250px;
  margin: auto;
  margin-top: 10px;
}
#searchLabel{
  margin: auto;
}

.settingsDiv{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.input-logo{
        background-image:url("../assets/searchicon.svg"); 
        background-position:right;   
        background-repeat:no-repeat;
        margin: 2px;
        padding-left:1px;
        font-size: 16px;
        width: 200px;
        border: solid #737373 1px;
  }

.nav{
  
  padding-right: 1%;
  margin-top: 3%;
  display: flex;
}
.navEntry{
  margin: auto;
}
.navEntry h3 {
    margin: auto;
}
#settingsIcon{
  height: 3vh;
}

#homeIcon{
  height: 3vh;
}

#securityIcon{
  height: 3vh;
}

#syncIcon{
  height: 3vh;
}

.contentDiv {
  margin: 3%;
}

.multiInput{
  margin-top: 2%;
}
#titleAllgemein {
  font-size: 3vh;
}
.titleCategory {
  display: block;
  font-weight: bold;
  margin-top: 5%;
}
</style>
<script>
// @ is an alias to /src
var placeholderText = ""
var showSearchLabel = false;
var showMultiInput = false;
export default {
  name: 'SettingsMobile',
  props: {
    taskOption: String
  },
  data: function(){
    return {
      placeholderText,
      showSearchLabel,
      showMultiInput
    }
  },
   created: function(){
     if (this.taskOption == 'SettingsLabel') {
       this.showSearchLabel = true
     } else if (this.taskOption == 'SettingsPlaceholder') {
       this.placeholderText = "Suchen"
     } else if (this.taskOption == 'SettingsMultiInput') {
       this.showMultiInput = true
     } 
  },
  methods: {
    searchBarClicked() {
      this.$emit('stop-timer')
    },
    enterPressed(){
      console.log("enterpressed")
      this.$emit('show-betweentask')     
    }
  }
}
</script>