import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [{
        path: "/",
        alias: "/introduction",
        name: "inctroduction",
        component: () =>
            import ("../views/Introduction")
    },
    {
        path: "/Settings:taskOption",
        alias: "/settings",
        name: "settings",
        component: () =>
            import ("../components/Settings")
    },
    {
        path: "/Hslu:taskOption",
        alias: "/hslu",
        name: "hslu",
        component: () =>
            import ("../components/Hslu")
    },
    {
        path: "/HsluMobile:taskOption",
        alias: "/hslumobile",
        name: "hslumobile",
        component: () =>
            import ("../components/HsluMobile")
    },
    {
        path: "/SettingsMobile:taskOption",
        alias: "/settingsmobile",
        name: "settingsmobile",
        component: () =>
            import ("../components/SettingsMobile")
    }
]


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router