import axios from "../../node_modules/axios";

class ApiService {
    submitTime(firstTaskID, firstTimeNeeded, secondTaskID, secondTimeNeeded, bIsMobile) {
        const params = new URLSearchParams();
        params.append('firstTaskID', firstTaskID);
        params.append('firstTimeNeeded', firstTimeNeeded);
        params.append('secondTaskID', secondTaskID);
        params.append('secondTimeNeeded', secondTimeNeeded);
        params.append('isMobile', bIsMobile);

        var testApi = 'https://marc.khdev.ch/finished'
        axios.get(testApi, {
            params: params
        })
    }
}

export default new ApiService();